 @tailwind base;
@tailwind components;
@tailwind utilities;
/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


 root {
  --color-dark: white;
 }
h2 {
  color: white !important; /* Overrides the `--color-dark` variable */
}

.pricing-title {
  color: #8a2be2 !important; /* Purple color */
}

/* .nav-menu {
  /* background-color: var(--color-dark); */
  /* padding: 20px 200px; */
/* } */ 
/* Cards and Dog Section for Desktop View */
.relative2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns for cards */
  grid-template-rows: repeat(2, auto); /* Two rows for cards */
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  gap: 1rem; /* Add space between cards */
  position: relative; /* Allow stacking */
  padding-top: 5rem; /* Adjust top padding for alignment */
}

/* Dog Section */
.dog {
  position: absolute;
  top: -5rem; /* Adjust vertical position above cards */
  grid-column: 1 / -1; /* Span all columns in the grid */
  justify-self: center; /* Center horizontally */
  z-index: 11; /* Ensure it stays above the cards */
}

.dog img {
  width: 300px; /* Adjust size for desktop */
  height: auto; /* Maintain aspect ratio */
}

/* Cards */
.card {
  background-color: #2A1245; /* Dark purple */
  border-radius: 3rem; /* Rounded corners */
  text-align: center;
  padding: 1rem;
  padding-left: 7rem;
  padding-right: 7rem;
  z-index: 10; /* Below the dog */
}

.card h3 {
  font-size: 2.00rem; /* Larger font for titles */
  color: #9747FF; /* Purple text */
}

.card p {
  font-size: 1.25rem; /* Larger font for text */
  color: white;
}

/* Video Section */
.video-container {
  width: 100%; /* Full-width container */
  max-width: 1280px; /* Limit maximum width */
  margin: 2rem auto; /* Center with margin */
  position: relative;
  overflow: hidden;
  aspect-ratio: 16/9; /* Maintain 16:9 ratio for desktop */
}

.video-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.main-container {
  padding: 4rem 2rem; /* Increase padding on top and bottom */
  margin-top: 2rem; /* Optional: Add margin to push content further down */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  background-color: transparent; /* Optional: Ensure no background conflicts */
}

@media (max-width: 768px) {

  .dynamic-html-content {
    margin-top: 2rem; /* Ensure space between main UI and injected HTML */
    padding: 1rem; /* Add padding for proper spacing */
    background-color: transparent; /* Optional: Add a background color if needed */
    position: relative; /* Ensure it does not overlap */
    z-index: 1; /* Ensure it appears correctly in the layout */
    overflow: hidden; /* Prevent unexpected overflow */
    width: 100%; /* Ensure it adapts to the container width */
  }
  
  .main-container {
    z-index: 10; /* Ensure the main UI is layered above dynamically added content if needed */
    position: relative;
    padding-top: 7rem;
  }
  
 

  .relative2 {
    height: auto;
    max-height: 75vh;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns for the cards */
    grid-template-rows: auto auto; /* Two rows for the cards */
    gap: 1rem; /* Space between grid items */
    justify-items: center; /* Center align items horizontally */
    align-items: center; /* Center align items vertically */
    padding-top: 7rem; /* Increased padding at the top */
    
  }

  .dog {
    grid-column: span 2; /* Make the dog span two columns in the grid */
    z-index: 20;
    width: 150px; /* Ensure the dog size remains consistent */
    height: 150px;
    justify-self: center; /* Center the dog horizontally */
    align-self: center; /* Center the dog vertically */
    margin-bottom: -2rem; /* Adjust vertical alignment */

  }

  .dog img {
    width: 100%;
    height: auto;
    margin-bottom: -18rem;
  }

  .card {
    position: relative; /* Cards will align properly within the grid */
    width: auto;
    padding: 1rem;
    text-align: center;
    background-color: #2A1245;
    border-radius: 1rem;
  }

  .card h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #9747FF;
  }

  .card p {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: white;
  }

  .absolute.w-full.h-full {
    width: 90%; /* Keep the grid width responsive */
    height: auto;
    margin: 0 auto;
  }

  .aspect-video {
    width: 100%;
    height: auto;
    aspect-ratio: 9/16; /* For mobile view */
  }
  @media (min-width: 768px) {
    .aspect-video {
      aspect-ratio: 16/9; /* For larger screens */
    }
  }
  
  .video-container {
    width: 100%; /* Full-width container */
    height: 100%; /* Full height of the parent container */
    display: flex; /* Center the video within the container */
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    position: relative; /* Ensure proper positioning */
    overflow: hidden; /* Prevent content overflow */
    aspect-ratio: 9/16;
  }
  
  .video-container iframe {
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
    position: absolute; /* Absolute position within the container */
    top: 0;
    left: 0;
    aspect-ratio: 9/16;
  }


  /* mentors */
  .header-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    flex-direction: row; /* Align horizontally */
  }
  
  .header-section h1 {
    flex: 1; /* Center-align heading */
    text-align: center;
  }
  
  .header-section button {
    flex: 0;
  }
  
  .header-section .text-card {
    flex: 1;
    text-align: left;
  }


}








@media (max-width: 600px) {
  .padding-top-mainpage {
    padding-top: 50vh;
  }

  h1 {
    margin-top: 150px;
    font-size: 6vw; /* Adjust font size relative to the viewport width */
    line-height: 1.2; /* Maintain proper line spacing */
    text-align: center; /* Ensure alignment on smaller screens */
  }
}



